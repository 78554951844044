<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">题库管理</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">模拟考试配置</a>
                </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl ">
          <div class="searchbox">
            <div title="模拟考试" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6em;">模拟考试:</span>
              <el-input size="small" v-model="searchForm.mockName" clearable placeholder="请输入模拟考试名称"></el-input>
            </div>
            <div title="题库" class="searchboxItem ci-full">
              <span class="itemLabel">题库:</span>
              <el-select
                  v-model="searchForm.bankId"
                  placeholder="请选择题库"
                  size="small"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in QuestionBankList"
                    :key="item.bankId"
                    :label="item.bankName"
                    :value="item.bankId"
                ></el-option>
              </el-select>

            </div>
            <div class="searchboxItem">
              <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
            </div>
            <div class="searchboxItem">
              <el-button type="primary" class="bgc-bv" round @click="updateTask('')">新建模拟试卷任务</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="listData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  type="index"
                  align="center"
                  :index="indexMethod"
                  width="50"
              />
              <el-table-column
                  label="模拟考试名称"
                  min-width="150"
                  prop="mockName"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="题库名称"
                  min-width="180"
                  prop="bankName"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="考试时长(分钟)"
                  min-width="120"
                  prop="duration"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="试卷数量"
                  min-width="100"
                  prop="paperNum"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="创建时间"
                  prop="createStr"
                  min-width="150"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="操作"
                  align="center"
                  fixed="right"
                  min-width="180"
              >
                <template v-slot="{row}">

                  <el-button type="text" style="padding: 0 5px;" @click="updateTask(row.mockId)">编辑</el-button>
                  <el-button type="text" style="padding: 0 5px;" @click="handleRecycleRelease(row.mockId,row.status)">{{ row.status == '00'?'发布':row.status == '10'?'回收':'未获取到发布状态' }}</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import List from "@/mixins/List.js"
import PageNum from "@/components/PageNum.vue";
import Empty from "@/components/Empty.vue";
// import {resetKeepAlive} from "@/utils/common";
export default {
  name:"SimulatedExamConfig",
  mixins:[List],
  components:{
    PageNum,
    Empty
  },
  data(){
    const validateFile=(rule,value,callback)=>{
      const {bankForm}=this;
      if(!bankForm.excelKey){
        callback("请上传学员导入模板");
        return;
      }
      callback();
    }
    return{
      bankId:'',
      // 题库下拉数据
      QuestionBankList:[],
      // 查询表单数据
      searchForm:{
        mockName:"",
        bankId:"",
      },
      // 分页器数据
      pagination:{
        total: 0,//总条数
        size: 10,//每页几条数据
        current: 1//当前页
      },
      // // 列表数据
      listData:[],
    }
  },
  created(){
    // 获取题库下拉
    this.getQuestionBank();
    console.log('query',this.$route.query)
    if(this.$route.query&&this.$route.query.refresh){
      this.pagination.current = this.$route.query.querypPageNum
      this.pagination.size = this.$route.query.queryPageSize
      this.searchForm.mockName = this.$route.query.queryMockName
      this.searchForm.bankId = this.$route.query.queryBankId

      this.getListData(this.$route.query.querypPageNum);
    }else{
      this.getListData();
    }
    // 获取列表数据



  },
  // beforeRouteLeave: resetKeepAlive,
  // watch: {
  //   $route: {
  //     handler: function (val, oldVal) {
  //       console.log(val.query)
  //       if (val.query.refresh) {
  //
  //
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
  methods:{

    // 获取题库列表
    getQuestionBank() {
      this.$post("/biz/exam/bank/pageList",{
        pageNum:1,
        pageSize:1000000
      },3000,true,6).then(res=>{
        // console.log(res?.data?.list)
        this.QuestionBankList = res?.data?.list||[];
      });

    },
    // 序号
    indexMethod(index) {
      return (this.pagination.current - 1) * this.pagination.size + index + 1;
    },
    // 获取列表数据
    getListData(current){
      const {pagination,searchForm}=this;
      if(current){
        pagination.current=current;
      }
      this.$post("/biz/exam/bank/mock/pageList",{
        pageNum:pagination.current,
        pageSize:pagination.size,
        ...searchForm
      },3000,true,6).then(res=>{
        console.log(res.data)
        this.listData=res?.data?.list||[];
        pagination.total=res?.data?.total||0;
      });
    },

    // 题库搜索方法
    getQuestionBankList(query) {
      if(query.trim().length<2){
        this.questionBankList=[];
        return;
      }
      this.$post("/biz/ct/project/selectQuestionBank",{
        questionBankName: query
      },3000,false).then(res => {
        this.questionBankList = res.data || [];
      });
    },

    //回收发布
    handleRecycleRelease(mockId,status) {
      this.$post('/biz/exam/bank/mock/updateStatus',{mockId,status:status == '00'?'10':'00'},3000,true,6).then(res => {
        if(res.status == '0') {
          this.$message.success(res.message);
          this.getListData();
        }
      })

    },

    // 新增编辑
    updateTask(mockId){
      this.$router.push({
        path:"/inkQuestionBank/SimulatedExamConfig/updateTask",
        query:{
          mockId,
          pageNum:this.pagination.current,
          pageSize:this.pagination.size,
          mockName:this.searchForm.mockName,
          bankId:this.searchForm.bankId,
        }
      });
    },

    // 添加题库
    addQuesBank(){
      this.bankDialogVisible=true;
    },

    // 当前页改变
    currentChange(current){
      const {pagination}=this;
      pagination.current=current;
      this.getListData();
    },
    // 当前页每页条数改变
    sizeChange(size){
      const {pagination}=this;
      pagination.size=size;
      this.getListData();
    },
    // 获取表格高度
    getTableHeight() {
      this.tableHeight = window.innerHeight - 260;
    },
  },

}
</script>

<style scoped lang="less">
/deep/.upload{
  height: 40px !important;
  .el-upload{
    height: 40px !important;
    border: none;
  }
}
// 上传文件列表样式
.fileList{
  margin-top: 40px;
  .file-item{
    display: flex;
    .el-button{
      margin-left: 10px;
    }
  }
}
// 培训任务选择框样式
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
// 错误信息样式
.errorInfo{
  display: flex;
  .success{
    color: #5C6BE8;
  }
  .error{
    margin-left: 20px;
    color: red;
  }
  .el-button{
    margin-left:20px
  }
}
</style>